<template>
  <!-- <v-snackbar v-model="alert" :color="color" multi-line top>
      {{ text }}
      <v-btn dark text @click="close">
        <v-icon>mdi-close-circle</v-icon>
      </v-btn>
  </v-snackbar> -->

<!-- <v-snackbar class="alert alert-warning alert-dismissible fade show" v-model="alert" >
    <button type="button" class="close" data-dismiss="alert" @click="close">&times;</button>
  {{ text }}
</v-snackbar> -->
<b-container >
  <b-row>

    <b-col v-if="status"  col lg="4" class="position-fixed fixed-bottom mx-auto m-0 rounded-0"
      style="z-index: 2000;" >
    <b-alert  v-model="alert" 
      :show="dismissCountDown"
      dismissible
      :variant="color"
      @dismissed="dismissCountDown=0"
      @dismiss-count-down="countDownChanged"
    >
     {{ text }}
    </b-alert>

  </b-col>

  </b-row>
</b-container>


</template>
<script>
import { mapGetters, mapActions } from 'vuex'
export default {
    name: 'Alert',
     data() {
      return {
        dismissSecs: 5,
        dismissCountDown: 0
      }
    },
    computed: {
      ...mapGetters({
        status : 'alert/status',
        color  : 'alert/color',
        text   : 'alert/text'
      }),
      alert: {
        get () {
          return this.status
        },
        set (value) {
          this.setAlert({
            status : value,
            type  : 'success',
            text  : 'test',
          })
        }
      },
      statusalert: function(){
        return this.status
      },
      
      
    },
    created(){
     
      if(this.statusalert==true){
          this.dismissCountDown = this.dismissSecs
      }
      
       
    },

    methods: {
      ...mapActions({
        setAlert  : 'alert/set',
      }),
      close(){
        this.setAlert({
          status : false
        })
      }, 
      countDownChanged(dismissCountDown) {
        this.dismissCountDown = dismissCountDown
           if(this.dismissCountDown == 0){
              this.setAlert({
                status : false
              })
        }
      },
      
      showAlert() {
        this.dismissCountDown = this.dismissSecs
      }  
    }
}
</script>

<style scoped>

</style>